import { pinyin } from "pinyin-pro";

export const pinyinSort = (a:string, b:string, desc?:boolean) => {
    const pinyinA = pinyin(a || '', { toneType: 'none' }).replace(/\s/g, '')
    const pinyinB = pinyin(b || '', { toneType: 'none' }).replace(/\s/g, '')
    if(desc){
        return pinyinB.localeCompare(pinyinA)
    }else{
        return pinyinA.localeCompare(pinyinB)
    }
}